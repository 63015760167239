export default

{
    version: '1.3.4',
    welcomeScreen: {
        welcome: 'Welcome',
        reload: 'Reload',
        clickReload: 'If you have just added games to your account, click reload.',
        findMore: 'Find more games to track in your account settings',
        songList: 'Songs list',
        courses: 'Courses',
        songs: 'Songs',
        system: 'System',
        arcade: 'Arcade',
        singles: 'Single Points',
        doubles: 'Double Points',
        singleCourses: 'Single Courses',
        doubleCourses: 'Double Courses',
        tips: 'How it works',
        selectGames: 'Add game',
        trackedGames: 'Tracked Games',
        highScoreRankings: 'High Score Rankings',
        highScore: 'High Score',
        leaderboard: 'Leaderboard',
        seeInfo: 'See Info',
        hideInfo: 'Hide Info',
    },
    menu: {
        app: 'App',
        overview: 'Dashboard',
        settings: 'Settings',
        logout: 'Logout',
        aboutAndInfo: 'About & Info',
        admin: 'Admin',
        back: 'Back',
        arcadeRanking: 'Arcade Ranking',
        games: 'Games',
        arcadesSelect: 'Arcades',
        login: 'Login',
        songLeaderboard: 'Song Leaderboard',
        profile: 'Profile',
    },
    listScreen: {
        player: 'Player | Players',
        game: 'Game | Games',
        search: 'Search',
        filters: 'Filters',
        target: 'Target',
        filter: 'Filter',
        nameArtist: 'Name / Artist',
        level: 'Level',
        filterOn: 'on',
        songName: 'Song Name',
        favorite: 'Favorite',
        artistAlias: 'Artist / Alias',
        favoriteShort: 'Fav',
        targetShort: 'Tar',
        searchName: 'Search by name',
        fullComboCap: 'FULL-COMBO',
        name: 'Name',
        artist: 'Artist',
        genre: 'Genre',
        easy: 'Easy',
        normal: 'Normal',
        hard: 'Hard',
        another: 'Another',
        title: 'Song name',
        easyLevel: 'Easy Level',
        normalLevel: 'Normal Level',
        hardLevel: 'Hard Level',
        anotherLevel: 'Another Level',
        easyDoubleLevel: 'E Double Level',
        normalDoubleLevel: 'N Double Level',
        hardDoubleLevel: 'H Double Level',
        anotherDoubleLevel: 'A Double Level',
        easyScore: 'Easy Score',
        normalScore: 'Normal Score',
        hardScore: 'Hard Score',
        anotherScore: 'Another Score',
        noSongs: 'No songs found',
    },
    filter: {
        songFilters: 'Song Filters',
        none: 'None',
        failed: 'Failed',
        clear: 'Cleared',
        cleared: 'Cleared',
        fullCombo: 'Full Combo',
        single: 'Single',
        double: 'Double',
        singles: 'Singles',
        doubles: 'Doubles',
        favorite: 'Favorite',
        target: 'Target',
        playStyle: 'Play Style',
        otherFilters: 'Other Filters',
        applyFilters: 'Apply Filters',
        filterOnClear: 'Filter on Clear',
        level: 'Level',
        fullComboShort: 'FC',
        setTarget: 'Set Target',
        setFavorite: 'Set Favorite',
        save: 'Save',
        grade: 'Grade',
        score: 'Score',
        update: 'Update',
        show: 'Show',
        filerOn: 'Filter on',
        onlySinglePlayer: 'Battle Mode not available',
        onlySinglePlayerInfo: 'When playing in multiplayer, the course can not be played in Battle Mode. The title will yellow out and the course will be switched to couple mode when selected.',
    },
    login: {
        viewProfile: 'View Profile',
        copyCode: 'Copy Code',
        copied: 'Code is copied',
        languageIs: 'English',
        close: 'Close',
        login: 'Login',
        selectLanguage: 'Language',
        rankingUrl: 'Ranking URL',
        aboutApp: 'About app',
        register: 'Register',
        password: 'Password',
        version: 'Version',
        profileUrl: 'Profile URL',
        email: 'Email Address',
        progressTracker: 'Progress tracker for retro music games',
        emailIsSave: 'We will never share your email with anyone else.',
        nameInProfile: 'Will be displayed in your profile.',
        gametagName: 'Game tag / name',
        formInvalid: 'Form is invalid',
        confirmPassword: 'Confirm Password',
        goLogin: 'Go to login',
        passwordReset: 'Password Reset',
        emailWillBeSendToReset: 'An email will be sent to you with instructions on how to reset your password.',
        sendReset: 'Send Reset Email',
    },
    settings: {
        settings: 'Account Settings',
        singlePLay: 'Single Play',
        doublePlay: 'Double Play',
        accountDetails: 'Account Details',
        gameSelect: 'Game Select',
        gameSelectInfo: 'Choose the games you want to track.',
        accountInfo: 'Set your account information.',
        arcadeCode: 'Arcade ranking code',
        info: 'Info',
        favoriteGame: 'Favorite Game',
        selectTheme: 'Select Theme',
        updated: 'Updated',
        codeSlot1: 'Slot 1',
        codeSlot2: 'Slot 2',
        codeSlot3: 'Slot 3',
        seeRanking: 'See Ranking',
    }
}